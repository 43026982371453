import * as React from "react"
import { css, SerializedStyles } from "@emotion/react"
import styled from "@emotion/styled"

import { mixins } from "../mixins"
import { typographyStyles } from "../components/typography/Typography"
import { colours } from "../variables/colour/Colour"
import { borderRadii } from "../variables/border/Radii"
import { breakpoints } from "../variables/layout/Breakpoints"
import {
  Layout,
  defaultSameLayoutString,
  useMemoLayout,
} from "../variables/layout/Layout"
import { horizontal } from "../variables/layout/Spacing"
import { Spinner } from "./Spinner"
import { Target } from "../types"

export type PrimaryProminence = "primary" | "primary-two"

export type SecondaryProminence = "secondary" | "secondary-two"

export type TertiaryProminence =
  | "tertiary"
  | "tertiary-inverted"
  | "tertiary-two"
  | "tertiary-two-inverted"

export type Prominence =
  | PrimaryProminence
  | SecondaryProminence
  | TertiaryProminence

const isTertiaryVariant = (p: Prominence): p is TertiaryProminence => {
  switch (p) {
    case "tertiary":
    case "tertiary-inverted":
    case "tertiary-two":
    case "tertiary-two-inverted":
      return true
    default:
      return false
  }
}

export type ButtonWidth = "content-width" | "full-width"

export type Icon = Left | Right | None

interface None {
  kind: "none"
}

interface Left {
  kind: "left"
  icon: React.ReactNode
}

interface Right {
  kind: "right"
  icon: React.ReactNode
}

// Tertiary CTAs cannot be disabled
export type ProminenceDisabled =
  | { prominence: PrimaryProminence | SecondaryProminence; disabled: boolean }
  | { prominence: TertiaryProminence; disabled: false }

export const doesProminenceImplyEnabled = isTertiaryVariant

/**
 * Pair up prominence & disabled; but only keep disabled if the prominence
 * permits it.
 */
export const toProminenceDisabled = (
  prominence: Prominence,
  disabled: boolean
): ProminenceDisabled =>
  doesProminenceImplyEnabled(prominence)
    ? { prominence, disabled: false }
    : { prominence, disabled }

type CommonCTAProps = ProminenceDisabled & {
  width: Layout<ButtonWidth> | ButtonWidth
  // for screen readers https://kittygiraudel.com/2020/12/04/a11y-advent-self-explanatory-links/
  descriptiveText?: string
  className?: string
  children?: React.ReactNode
}

const ctaTypographyStyle = typographyStyles.bodyBold

const borderRadius = borderRadii.l

const ctaStyles = ({
  disabled,
  prominence,
  width,
}: CommonCTAProps): SerializedStyles => {
  // As well as <Layout> objects, the user can pass a ButtonWidth directly that
  // should be applied to all breakpoints
  width = defaultSameLayoutString(width)

  // Starting styles for each CTA type
  const baseCss = (): SerializedStyles | string => {
    switch (prominence) {
      case "primary":
      case "primary-two":
      case "secondary":
      case "secondary-two": {
        return css`
          height: 48px;
          padding: 0 ${horizontal.l};
          border-radius: ${borderRadius};

          ${breakpoints.desktop`
            height: 56px;
          `}
        `
      }
      case "tertiary":
      case "tertiary-inverted":
      case "tertiary-two":
      case "tertiary-two-inverted":
        return `
          height: 48px; // Always 48, even on desktop
          align-items: flex-end;
          padding: 12px 0 8px 0;
          background-color: transparent;

          > span {
            padding-bottom: 2px;
            border-bottom: 2px solid ${
              prominence === "tertiary"
                ? colours.offBlack
                : prominence === "tertiary-inverted"
                  ? colours.white
                  : "transparent"
            };
          }
        `
    }
  }

  // Styles for each CTA type when enabled
  const enabledCss = (): string => {
    switch (prominence) {
      case "primary": {
        return `
          color: ${colours.white};
          background-color: ${colours.action.main};
          border: 2px solid transparent;

          &:hover:not(:active) {
            color: ${colours.offBlack};
            background-color: ${colours.white};
            border-color: ${colours.offBlack};
          }
        `
      }
      case "primary-two": {
        return `
          color: ${colours.white};
          background-color: ${colours.offBlack};
          border: 2px solid transparent;

          &:hover:not(:active) {
            color: ${colours.offBlack};
            background-color: ${colours.white};
            border-color: ${colours.offBlack};
          }
        `
      }
      case "secondary": {
        return `
          color: ${colours.offBlack};
          background-color: ${colours.white};

          &:before {
            // Put the border on the :before so it will be included in the
            // opacity change when disabled
            border: 2px solid ${colours.offBlack};
          }

          &:hover:not(:active) {
            color: ${colours.white};
            background-color: ${colours.offBlack};
          }
        `
      }
      case "secondary-two": {
        return `
          color: ${colours.offBlack};
          background-color: transparent;

          &:before {
            // Put the border on the :before so it will be included in the
            // opacity change when disabled
            border: 2px solid ${colours.offBlack};
          }

          &:hover:not(:active) {
            color: ${colours.white};
            background-color: ${colours.offBlack};
          }
        `
      }
      case "tertiary":
      case "tertiary-two": {
        return `
          color: ${colours.offBlack};

          &:hover:not(:active) {
            > span {
              border-color: ${
                prominence === "tertiary" ? "transparent" : colours.offBlack
              };
            }
          }
        `
      }
      case "tertiary-inverted":
      case "tertiary-two-inverted": {
        return `
          color: ${colours.white};

          &:hover:not(:active) {
            > span {
              border-color: ${
                prominence === "tertiary-inverted"
                  ? "transparent"
                  : colours.white
              };
            }
          }
        `
      }
    }
  }

  // Styles for each CTA type when disabled
  const disabledCss = (): string => {
    switch (prominence) {
      case "primary":
      case "primary-two":
      case "secondary":
        return `
          &:before {
            background-color: ${colours.white};
            opacity: 0.5;
          }
        `

      case "secondary-two":
        return `
          &:before {
            background-color: ${colours.white};
            opacity: 0.5;
          }
        `

      case "tertiary":
      case "tertiary-inverted":
      case "tertiary-two":
      case "tertiary-two-inverted":
        return ""
    }
  }

  const renderButtonWidth = (width: ButtonWidth): string =>
    `width: ${width === "full-width" ? "100%" : "auto"};`

  return css`
    ${renderButtonWidth(width.mobile)}
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    ${ctaTypographyStyle}
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    border: 0;

    &:before {
      content: "";
      background: transparent;
      border-radius: ${isTertiaryVariant(prominence) ? "0" : borderRadius};
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &:focus:hover {
      outline: none;
    }

    &:focus-visible {
      ${mixins.focused}
    }

    ${baseCss()}
    ${enabledCss()}
    ${disabled
      ? `
        ${disabledCss()}
        pointer-events: none;
        cursor: default;
      `
      : ``}

    ${breakpoints.tablet`
      ${renderButtonWidth(width.tablet)}
    `}

    ${breakpoints.desktop`
      ${renderButtonWidth(width.desktop)}
    `}
  `
}

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  // The box around the icon is always 24px high to match the text line-height
  height: 24px;

  // Don't shrink the icon when we apply the padding in LeftIcon/RightIcon
  box-sizing: content-box;

  > svg {
    width: 24px;
    height: 24px;
  }
`

const LeftIcon = styled(Icon)`
  padding-right: 8px;
`

const RightIcon = styled(Icon)`
  padding-left: 8px;
`

const Text = styled.span`
  ${ctaTypographyStyle};
`

const CTAText: React.FC<{ text?: string; descriptiveText?: string }> = ({
  text,
  descriptiveText,
}) => {
  return (
    <React.Fragment>
      <Text aria-hidden={!!descriptiveText}>{text}</Text>
      {descriptiveText && (
        <span css={css(mixins.invisible)}>{descriptiveText}</span>
      )}
    </React.Fragment>
  )
}

type Role = "tab"

export type ButtonCTAProps = {
  id?: string
  isLoading?: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
  text?: string
  type?: "button" | "reset" | "submit"
  icon?: Icon
  component?: ButtonCTACustomComponent
  role?: Role
} & Partial<CommonCTAProps>

// The nested component may not specify a component of its own
export type ButtonCTACustomComponent = React.FC<
  Omit<ButtonCTAProps, "component">
>

export const ButtonCTA = React.forwardRef<HTMLButtonElement, ButtonCTAProps>(
  (
    {
      icon = { kind: "none" },
      disabled = false,
      prominence = "primary",
      width = "full-width",
      component,
      text,
      descriptiveText,
      ...props
    },
    ref
  ) => {
    width = useMemoLayout(width)

    /**
     * We need to memoise this because otherwise we will create a brand new
     * styled component on every render, instead of just re-rendering the one
     * we already have. That would be bad for performance, and potentially buggy
     */
    const Component = React.useMemo(() => {
      const styles = ctaStyles({
        ...toProminenceDisabled(prominence, disabled),
        width,
      })

      if (component) {
        return styled(component)`
          ${styles}
        `
      } else {
        return styled.button`
          ${styles}
        `
      }
    }, [component, prominence, disabled, width])

    return (
      <Component disabled={disabled} ref={ref} {...props}>
        {!props.isLoading && icon.kind === "left" && (
          <LeftIcon> {icon.icon} </LeftIcon>
        )}
        {icon.kind === "left" && props.isLoading && (
          <LeftIcon>
            <Spinner />
          </LeftIcon>
        )}

        <CTAText text={text} descriptiveText={descriptiveText} />

        {icon.kind === "right" && !props.isLoading && (
          <RightIcon> {icon.icon} </RightIcon>
        )}
        {icon.kind !== "left" && props.isLoading && (
          <RightIcon>
            <Spinner />
          </RightIcon>
        )}
      </Component>
    )
  }
)

export type LinkCTAProps = {
  href: string
  target?: Target
  text?: string
  // onClick is optional for links because there's a reasonable default: go to
  // the target.
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  icon?: Icon
  component?: LinkCTACustomComponent
} & Partial<CommonCTAProps>

// The nested component may not specify a component of its own
export type LinkCTACustomComponent = React.FC<Omit<LinkCTAProps, "component">>

const LinkCTAInternal = styled.a``

type LinkCTAInternalProps = React.ComponentPropsWithoutRef<
  typeof LinkCTAInternal
>

interface LinkCTAMemo {
  DefaultComponent: React.FunctionComponent<LinkCTAInternalProps>
  CustomComponent?: LinkCTACustomComponent
}

export const LinkCTA: React.FunctionComponent<LinkCTAProps> = ({
  href,
  text,
  descriptiveText,
  icon = { kind: "none" },
  target = "_self",
  disabled = false,
  prominence = "primary",
  width = "full-width",
  component,
  ...props
}) => {
  width = useMemoLayout(width)

  /**
   * We pass different sets of props to the default `a` element than we do to
   * custom components. The easiest way to do this is to create both component
   * types, and then choose which one to render later. This way TS is able to
   * verify that the prop types all line up.
   *
   * We need to memoise this because otherwise we will create a brand new
   * component on every render, instead of just re-rendering the one we already
   * have.
   */
  const { DefaultComponent, CustomComponent } =
    React.useMemo<LinkCTAMemo>(() => {
      const styles = ctaStyles({
        ...toProminenceDisabled(prominence, disabled),
        width,
      })

      const DefaultComponent = styled(LinkCTAInternal)`
        ${styles}
      `
      const CustomComponent =
        component &&
        styled(component)`
          ${styles}
        `

      return { DefaultComponent, CustomComponent }
    }, [component, prominence, disabled, width])

  /**
   * The children that we pass to `a` elements / custom components are the same,
   * however, so build them a single time to avoid duplication
   */
  const children = (
    <React.Fragment>
      {icon.kind === "left" && <LeftIcon> {icon.icon} </LeftIcon>}

      <CTAText text={text} descriptiveText={descriptiveText} />

      {icon.kind === "right" && <RightIcon> {icon.icon} </RightIcon>}
    </React.Fragment>
  )

  if (CustomComponent) {
    return (
      <CustomComponent
        disabled={disabled}
        href={href}
        target={target}
        {...props}
      >
        {children}
      </CustomComponent>
    )
  } else {
    return (
      <DefaultComponent
        // https://www.scottohara.me//blog/2021/05/28/disabled-links.html
        href={disabled ? undefined : href}
        aria-disabled={disabled}
        role={disabled ? "link" : undefined}
        target={target}
        {...props}
      >
        {children}
      </DefaultComponent>
    )
  }
}

/**
 * Later on in this file, we pre-apply a specific prominence, so we need to drop
 * that from the exposed props.
 */
export type ApplyProminence<
  P extends Prominence,
  Props extends { prominence?: Prominence },
> = Omit<Props & { prominence: P }, "prominence">

/**
 * Build ButtonCTA FunctionComponent by pre-applying a specific "prominence".
 */
const mkCTA =
  <
    Prom extends Prominence,
    Props extends ApplyProminence<Prom, ButtonCTAProps>,
  >(
    prominence: Prom
  ): React.VoidFunctionComponent<Props> =>
  props => <ButtonCTA {...props} prominence={prominence} />

/**
 * Build LinkCTA FunctionComponent by pre-applying a specific "prominence".
 */
const mkLink =
  <Prom extends Prominence, Props extends ApplyProminence<Prom, LinkCTAProps>>(
    prominence: Prom
  ): React.VoidFunctionComponent<Props> =>
  props => <LinkCTA {...props} prominence={prominence} />

export const PrimaryCTA = mkCTA("primary")

export const PrimaryLink = mkLink("primary")

export const PrimaryTwoCTA = mkCTA("primary-two")

export const PrimaryTwoLink = mkLink("primary-two")

export const SecondaryCTA = mkCTA("secondary")

export const SecondaryLink = mkLink("secondary")

export const SecondaryTwoCTA = mkCTA("secondary-two")

export const SecondaryTwoLink = mkLink("secondary-two")

export const TertiaryCTA = mkCTA("tertiary")

export const TertiaryLink = mkLink("tertiary")

export const TertiaryInvertedCTA = mkCTA("tertiary-inverted")

export const TertiaryInvertedLink = mkLink("tertiary-inverted")

export const TertiaryTwoCTA = mkCTA("tertiary-two")

export const TertiaryTwoLink = mkLink("tertiary-two")

export const TertiaryTwoInvertedCTA = mkCTA("tertiary-two-inverted")

export const TertiaryTwoInvertedLink = mkLink("tertiary-two-inverted")
